.intro {
  display: block;
  position: relative;
  width: 100%;
  padding: 100px 0 335px;
  @include media-breakpoint-down(md) {
    padding: 100px 0 200px;
  }
  @include media-breakpoint-down(xs) {
    padding: 100px 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 15%;
    @include gradient-y(rgba(4, 4, 7, 1), rgba(0, 0, 0, 0));
  }

  .intro-title {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 300;
    font-size: 36px;
    color: white;
    margin-bottom: 110px;
    z-index: 2;
  }

  .intro-item {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;

    .intro-image {
      display: block;
      width: 100%;
      text-align: center;
      min-height: 280px;
      margin-bottom: 10px;
    }

    .intro-item-title {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;

      a {
        color: white;
		@include media-breakpoint-down(xs) {
			font-size: 150%;
		}
      }
    }
  }

  .intro-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    @include translate(0, 50%);
    @include media-breakpoint-down(xs) {
      padding: 0 15px;
      position: relative;
	  top: 30vh;
      @include translate(0, 0);
    }

    .intro-content-block {
      display: block;
      width: 100%;
      text-align: left;
      padding: 130px 120px;
      background: url("../img/bg-item.jpg") no-repeat center top / cover;
      @include media-breakpoint-down(md) {
        padding: 45px 35px;
      }
      @include media-breakpoint-down(xs) {
        padding: 25px 15px;
		text-align: center!important;
      }

      p {
        font-size: 36px;
        line-height: 1.35;
        color: white;
        @include media-breakpoint-down(md) {
          font-size: 22px;
        }
		@include media-breakpoint-down(lg) {
		  font-size: 28px;
		}
        @include media-breakpoint-down(xs) {
          font-size: 7vw;
        }
      }
    }
  }
}
