$base-font: 'Lato', sans-serif;
$font-size: 16px;
$text-color: #5f5f5f;
$text-orange-color: #f85220;
$text-sub-color: #003b79;
$text-footer: #ffffff;
$bg-color: #040407;
$bg-color-detail: #f0f0f0;
$bg-color-main: #01437d;
$bg-color-footer: #012b5d;
$bg-color-sub: #105893;