.footer {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0 55px;
  @include media-breakpoint-down(md) {
    padding: 45px 0 55px;
  }
  .footer-contact {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    @include translate(0, -10%);
    @include media-breakpoint-down(xs) {
      position: relative;
      padding: 0 15px;
	  top: -15vh;
      @include translate(0, 0);
    }

    .footer-contact-block {
      display: block;
      width: 100%;
      text-align: right;
      padding: 60px;
      background: #68acdf;
      @include media-breakpoint-down(md) {
        padding: 60px 25px;
      }
      @include media-breakpoint-down(xs) {
        text-align: center;
        margin-bottom: 25px;
      }

      &:after {
        display: block;
        background: url("../img/icon-contact.png");
        width: 94px;
        height: 93px;
        content: '';
        position: absolute;
        right: 80px;
        top: -45px;
        @include translate(0, 0);
        @include media-breakpoint-down(xs) {
          right: 50%;
          @include translate(50%, 0);
        }
      }

      a {
        display: block;
        width: 100%;
        font-size: 36px;
        line-height: 1.35;
        color: white;
        text-align: right;
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 32px;
          text-align: center;
        }

        &:first-child {
          margin-bottom: 25px;
        }
      }
    }
  }

  .footer-content {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
	@include media-breakpoint-down(xs) {
	  text-align: center;
	}

    p {
      color: white;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.35;
	  
	  &.bold {
	  	font-weight: 700;
	  }
    }
  }
  
  .text-right {
  	@include media-breakpoint-down(xs) {
  	  text-align: center!important;
  	}
  }

  .copy-right {
    position: relative;

    p, #currentyear {
      font-size: 14px;
      font-weight: 300;
      color: white;
    }
  }
}
