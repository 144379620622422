* {
  outline: none;
}

html {
  width: 100%;
  height: 100%;
  background-color: #040407;
}

// Font smoothing hack
html, html a {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

body {
  background-color: $bg-color;
  color: $text-color;
  font-family: $base-font;
  font-size: $font-size;
  overflow-x: hidden;
  letter-spacing: -0.25px;
  font-style: normal;

  &.darkHeader #BackToTop {
    right: 15px;
  }
}

input, textarea, button, a, p, span {
  font-family: $base-font;
}

input, textarea, button, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  min-height: 50px;
  resize: vertical;
}

a, a:hover, a:focus, a:active {
  color: $text-color;
  text-decoration: none !important;
  cursor: pointer;
}

p {
  color: $text-color;
  font-size: $font-size;
  line-height: 20px;
}

strong {
  font-weight: 600;
}

*:focus, :active {
  outline: none !important;
  box-shadow: none;
}

#BackToTop {
  border: 2px solid #fff;
  width: 4em;
  height: 4em;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-indent: -9999em;
  display: block;
  position: fixed;
  right: -100px;
  bottom: 15px;
  transition: right 0.3s;
  z-index: 10;

  &:before {
    content: ' ';
    border: 2px solid #fff;
    border-color: #fff transparent transparent #fff;
    width: 1.8em;
    height: 1.8em;
    display: inline-block;
    position: absolute;
    left: 1.1em;
    top: 1.6em;
    margin-left: -2px;
    margin-top: -2px;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
  }
}
