.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -101;

  .fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
  }
}


@media (min-aspect-ratio: 16 / 9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%
  }
}

@media (max-aspect-ratio: 16 / 9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%
  }
}

@media (max-width: 767px) {
  .fullscreen-bg {
    background: url("../img/video-banner.jpg") center center/cover no-repeat
  }

  .fullscreen-bg__video {
    display: none
  }
}
