.partner {
  display: block;
  position: relative;
  width: 100%;
  padding: 390px 0;
  background: white;
  @include media-breakpoint-down(md) {
    padding: 220px 0 290px;
  }
  @include media-breakpoint-down(xs) {
    padding: 190px 0 290px;
  }
  
  div.col-sm {
  	@include media-breakpoint-up(sm) {
	  	// chia 1 hàng 5 item ap dung cho desktop
	  	-webkit-box-flex: 0;
	    -ms-flex: 0 0 20%;
	    flex: 0 0 20%;
	    max-width: 20%;
  	}
  }

  .logo-branch {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 120px;
	
	.text-right {
		@include media-breakpoint-down(xs) {
		  text-align: center!important;
		}
	}
  }

  .partner-img {
    display: block;
    width: 100%;
    text-align: center;

    img {
      //@include grayscale_element(100%);
	  transform: scale(1, 1);
      @include transition(all 0.25s);
    }

    &:hover {
      img {
        //@include grayscale_element(0);
		transform: scale(1.25, 1.25);
		@include transition(all 0.25s);
      }
    }
  }
}
