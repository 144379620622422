.header {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../img/banner.jpg") no-repeat center bottom / cover;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 5%;
    @include gradient-y(rgba(0, 0, 0, 0), rgba(4, 4, 7, 1));
  }

  .background {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .header-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    @include translate(-50%, -50%);

    .header-branch {
      margin-bottom: 50px;
    }

    .slogan {
      display: block;
      position: absolute;
      left: 110px;
      bottom: -50px;
      text-align: center;
      font-size: 50px;
      font-weight: 300;
      color: white;
      @include calc(width, '100vw - 35px');
      @include translate(-50%, 0);
      @include media-breakpoint-down(xs) {
        font-size: 34px;
		left: 25vw;
      }

      span {
        font-weight: bold;

        &.typed-cursor {
          font-weight: 300;
        }
      }
    }
  }

  .scroll-down a {
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-top: 70px;
    color: #09387d;
    font: normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;

    &:hover {
      opacity: .5;
    }

    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      border-left: 1px solid #09387d;
      border-bottom: 1px solid #09387d;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-animation: sdb05 1.5s infinite;
      animation: sdb05 1.5s infinite;
      box-sizing: border-box;
    }
  }
}


@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

