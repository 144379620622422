@font-face {
	font-family: "iconfont";
	src: url('fonts/iconfont.eot');
	src: url('fonts/iconfont.eot?#iefix') format('eot'),
		url('fonts/iconfont.woff2') format('woff2'),
		url('fonts/iconfont.woff') format('woff'),
		url('fonts/iconfont.ttf') format('truetype'),
		url('fonts/iconfont.svg#iconfont') format('svg');
}

.icon:before {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-align-justify:before {
	content: "\E001";
}

.icon-chevron-left:before {
	content: "\E002";
}

.icon-chevron-right:before {
	content: "\E003";
}

.icon-phone-alt:before {
	content: "\E004";
}

.icon-user:before {
	content: "\E005";
}
