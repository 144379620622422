.hotline-wrapper {
  display: block;
  position: fixed;
  right: 15px;
  bottom: 60px;
  z-index: 99;
  @include media-breakpoint-down(xs) {
    bottom: 15px;
  }

  .social-action {
    display: block;
    width: 100%;
    text-align: right;

    a {
      display: block;
      -webkit-animation: mover 1s infinite alternate;
      animation: mover 1s infinite alternate;

      img {
        @include media-breakpoint-down(xs) {
          width: 50px;
        }
      }
    }
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
